import MenuButton from "@components/components/Menu/MenuButton";
import {AnchorPosition} from "@components/components/Menu/types";
import {ListOption} from "@components/OptionsList";
import {Item} from "@react-stately/collections";
import {setLanguage, useTranslation} from "ni18n";
import React, {useCallback, useMemo} from "react";
import {languages, selectable} from "src/languages.js";

interface NavLanguageSelectorProps {
  renderButton: (
    props: React.ButtonHTMLAttributes<HTMLButtonElement> & {selectedItem: string},
  ) => JSX.Element;
  anchorDirection?: AnchorPosition;
}

export const NavLanguageSelector: React.FC<NavLanguageSelectorProps> = ({
  renderButton,
  anchorDirection = "bottom left",
}) => {
  const i18n = useTranslation();

  const options = useMemo(
    () =>
      languages
        .filter(lng => selectable.includes(lng.code))
        .map<ListOption<string>>(lng => ({
          text: lng?.native,
          value: lng.code,
        })),
    [],
  );

  const selected = options.find(({value}) => value === i18n.lang.substr(0, 2));
  const selectedKeys = useMemo(() => [selected?.value], [selected?.value]);

  const onAction = useCallback((code: string) => setLanguage(code), []);
  const _renderButton = useCallback(
    props => renderButton({selectedItem: selected?.text as string, ...props}),
    [renderButton, selected?.text],
  );

  const renderItem = useCallback(item => <Item key={item.value}>{item.text}</Item>, []);

  return (
    <MenuButton
      label={i18n.t("Select Language")}
      // @ts-expect-error TS2322: Type '(code: string) => Promise<boolean>' is not assignable to type '(key: Key) => void'.
      onAction={onAction}
      // @ts-expect-error TS2322: Type '(string | undefined)[]' is not assignable to type '"all" | Iterable<Key> | undefined'.
      selectedKeys={selectedKeys}
      items={options}
      anchorDirection={anchorDirection}
      renderButton={_renderButton}
    >
      {renderItem}
    </MenuButton>
  );
};
