import {OverlayContainer} from "@react-aria/overlays";
import React, {useCallback, useEffect, useMemo} from "react";

import {useDisclosure} from "../../../../hooks/useDisclosure";
import ModalDialog from "./ModalDialog";

/*
  Implementation of Dialog pattern
  Sourced from @react-aria's implementation: https://react-spectrum.adobe.com/react-aria/useDialog.html
 */
export const useDialog = ({
  title,
  isFullWidth = false,
  isTitleHidden = false,
  blurBackdrop = false,
  onStateChange,
}: {
  title: string;
  isFullWidth: boolean;
  isTitleHidden: boolean;
  blurBackdrop: boolean;
  onStateChange?: (isOpen: boolean) => void;
}): {
  state: ReturnType<typeof useDisclosure>;
  Dialog: React.FC;
} => {
  const state = useDisclosure();

  useEffect(() => {
    if (onStateChange) {
      onStateChange(state.isOpen);
    }
  }, [onStateChange, state.isOpen]);

  const Dialog = useCallback(
    ({children}: {children: React.ReactNode}) =>
      state.isOpen ? (
        <OverlayContainer>
          <ModalDialog
            id="modal-dialog"
            role="dialog"
            title={title}
            isDismissable
            isFullWidth={isFullWidth}
            isTitleHidden={isTitleHidden}
            blurBackdrop={blurBackdrop}
          >
            {children}
          </ModalDialog>
        </OverlayContainer>
      ) : null,

    [blurBackdrop, isFullWidth, isTitleHidden, state.isOpen, title],
  );

  // @ts-expect-error TS2322: Type '{ state: { set: React.Dispatch<React.SetStateAction<boolean>>; close: () => void; open: () => void; toggle: () => void; isOpen: boolean; isClosed: boolean; }; Dialog: ({ children }: { children: React.ReactNode; }) => JSX.Element | null; }' is not assignable to type '{ state: { set: Dispatch<SetStateAction<boolean>>; close: () => void; open: () => void; toggle: () => void; isOpen: boolean; isClosed: boolean; }; Dialog: FC<{}>; }'.
  return useMemo(
    () => ({
      state,
      Dialog,
    }),
    [state, Dialog],
  );
};
