import {useTranslation} from "ni18n";
import {useMemo} from "react";

import {IconName} from "../_services/types";
import {ExtendedCareEntrySearchResult} from "../components/v5/CareEntryRow";
import {ApptReasonId} from "../constants/apptReasons";
import {CareSlug} from "../constants/cares";
import {useTypedSelector} from "../store";

type CareExtension = {
  icon?: IconName;
  turnaround?: string;
  price?: string;
};

/**
 * This is a hopefully* temporary utility function to enhance reason UI.
 * Icons should be updated in database instead of being overwritten like this.
 * Price and turnaround should also be dynamic.
 */
export const useResultExtensions = (): Record<string, CareExtension> => {
  const i18n = useTranslation();

  return useMemo(
    () => ({
      [CareSlug.FAMILY_PLANNING]: {
        icon: "child",
      },
      [CareSlug.SORE_THROAT]: {
        icon: "sore-throat",
      },
      [CareSlug.WORKPLACE_HEALTH]: {
        icon: "worker-with-heart",
      },
      [CareSlug.EAR_ACHE]: {
        icon: "ear",
      },
      [CareSlug.PRIMARY_CARE]: {
        icon: "doc-with-stethoscope",
      },
      [CareSlug.URGENT_CARE]: {
        icon: "shield-and-cross",
      },
      [CareSlug.ANNUAL_PHYSICAL]: {
        icon: "calendar-heart",
      },
      [CareSlug.WOMENS_HEALTH]: {
        icon: "person-with-heart",
      },
      [CareSlug.COLD_AND_FLU]: {
        icon: "cup-of-tea",
      },
      [CareSlug.INJURY]: {
        icon: "arm-in-sling",
      },
      [CareSlug.RASH]: {
        icon: "chest-rash",
      },
      [CareSlug.URINARY_TRACT_INFECTION]: {
        icon: "uterus",
      },
      [CareSlug.ABDOMINAL_PAIN]: {
        icon: "tummy-ache",
      },
      [CareSlug.COVID_CARE]: {
        icon: "covid-19-virus",
      },
      [CareSlug.COVID_EXPOSURE_SCREENING]: {
        icon: "icon-microscope",
        turnaround: i18n.t("Results same-day"),
        price: i18n.t("Covered by insurance"),
      },
      [CareSlug.COVID_TESTING]: {
        icon: "flu-screening",
      },
      [CareSlug.COVID_TREATMENT]: {
        icon: "covid-19-virus",
      },
      [CareSlug.COVID_VACCINATION]: {
        icon: "syringe",
      },
      [CareSlug.SAME_DAY_NAAT]: {
        icon: "rapid-hepatitis-c-screening",
        turnaround: i18n.t("Results same-day"),
        price: i18n.t("$150-200"),
      },
      [ApptReasonId.COVID_SICK_VISIT]: {
        icon: "general-surgery",
        turnaround: i18n.t("Results same-day"),
        price: i18n.t("Covered by insurance"),
      },
      [ApptReasonId.COVID_VAX_FIRST_DOSE]: {
        icon: "syringe",
      },
      [ApptReasonId.COVID_VAX_SECOND_DOSE]: {
        icon: "syringe",
      },
      [ApptReasonId.COVID_VAX_ADDITIONAL_DOSE]: {
        icon: "syringe",
      },
      [ApptReasonId.COVID_TESTING_NO_SYMPTOMS]: {
        icon: "lab-testing2",
        turnaround: i18n.t("Results same-day"),
        price: i18n.t("Covered by insurance"),
      },
      [ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING]: {
        icon: "rapid-hepatitis-c-screening",
        turnaround: i18n.t("Results same-day"),
        price: "$150 - 250",
      },
      [ApptReasonId.NEXT_DAY_RT_PCR]: {
        icon: "lab-testing2",
        turnaround: i18n.t("Results 2pm next day"),
        price: "$150-250",
      },
      [ApptReasonId.SAME_DAY_ANTIGEN]: {
        icon: "rapid-hepatitis-c-screening",
        turnaround: i18n.t("Results same-day"),
        price: "$60",
      },
      [ApptReasonId.SAME_DAY_RT_PCR]: {
        icon: "rapid-hepatitis-c-screening",
        turnaround: i18n.t("Results same-day"),
        price: "$150 - 200",
      },
      [ApptReasonId.COVID_I_TESTED_POSITIVE]: {
        price: i18n.t("Covered by insurance"),
      },
      [ApptReasonId.VIRTUAL_COVID_19_VISIT]: {
        price: i18n.t("Covered by insurance"),
      },
    }),
    [i18n],
  );
};

export const useExtendCareEntries = (
  careEntries: ExtendedCareEntrySearchResult[],
): ExtendedCareEntrySearchResult[] => {
  const extensions = useResultExtensions();
  const {reasons = []} = useTypedSelector(state => state.config);

  return useMemo(
    () =>
      careEntries.map(careEntry => {
        const idKey =
          typeof careEntry.appointment_reason === "string" ? careEntry.appointment_reason : "";
        const idExtension = extensions[idKey as keyof typeof extensions] || {};
        const slugExtension = extensions[careEntry.slug as keyof typeof extensions] || {};

        const extendedResult = {...careEntry, ...slugExtension, ...idExtension};
        const icon =
          extendedResult.icon ||
          reasons?.find(reason => reason.slug === careEntry.slug)?.iconName ||
          // This is a hack for the plane icon, so its the same as in the modal.
          // TODO > Needs refactoring when the CMS is properly configured. > GROWTHRND-931
          (careEntry.slug === "covid-19-travel-testing" && "flight") ||
          undefined;

        return {
          ...extendedResult,
          icon,
        };
      }),
    [careEntries, extensions, reasons],
  );
};
