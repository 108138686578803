import LocationDetailsCard from "@components/Locations/LocationDetailsCard";
import NextImage from "@components/NextImage";
import {useTranslation} from "ni18n";
import React from "react";
import {SoonestSlotTimeByLocationAndSpecialty} from "src/hooks/useGetSoonestTime";

import {RootStateLocation} from "../../../store/types";
import {s3ImageSource} from "../../../useS3ImgSrc";
import {getOpenTime} from "../../../utils/timeUtils";

export interface LocationExplorerCard {
  location: RootStateLocation;
  soonestSlots: SoonestSlotTimeByLocationAndSpecialty;
}

// @ts-expect-error TS7031, TS7031: Binding element 'location' implicitly has an 'any' type.,  Binding element 'soonestSlots' implicitly has an 'any' type.
export const LocationExplorerCard = ({location, soonestSlots}) => {
  const i18n = useTranslation();
  const {timeString, timeBlock, isOpenNow, isBeforeOpeningToday, daysFromToday} = getOpenTime(
    i18n,
    location,
  );

  return (
    <li className="df fdc mb8-md">
      <div className="pos-r mb4" style={{height: 250}}>
        <NextImage
          className="br1"
          objectPosition="50% 50%"
          layout="fill"
          src={s3ImageSource(location.images[0].imageId, "jpg", 2)}
          alt=""
        />
      </div>
      <LocationDetailsCard
        location={location}
        timeBlock={timeBlock}
        timeString={timeString}
        isOpenNow={isOpenNow}
        isBeforeOpeningToday={isBeforeOpeningToday}
        hideButton={false}
        buttonText={i18n.t("View Clinic")}
        daysFromToday={daysFromToday}
        specialtyBoxMode={false}
        soonestSlots={soonestSlots}
        condensed={false}
      />
    </li>
  );
};
