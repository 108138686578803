import Regions from "@data/localRegions.json";

import hardcodedVirtualRegions from "../constants/hardcodedVirtualRegions";
import {RegionSlug} from "../store/types";
import type {Region as _Region} from "../store/types";

export type Region = {
  key: RegionSlug;
  name: string;
  textValue: string;
  slug: RegionSlug;
};

const regionHasPhysicalClinics = (region: _Region) =>
  !hardcodedVirtualRegions.map(({slug}) => slug).includes(region.slug);

export const generateRegionData = (hideVirtualOnly = false): Region[] => {
  const regions = hideVirtualOnly ? Regions.filter(regionHasPhysicalClinics) : Regions;
  return regions.map(region => ({
    key: region.slug,
    textValue: region.name,
    name: region.name,
    slug: region.slug,
  }));
};
