import Notice, {NoticeType} from "@components/Maps/Notice";
import {Collapse} from "@mui/material";
import {FeatureFlag} from "@services/featureFlagConstants";
import {fetchFeatureFlags} from "@services/featureFlags";
import {IconName} from "@services/types";
import moment from "moment-timezone";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useEffect, useRef, useState} from "react";

import {bannerSpacer} from "../../constants/dynamicBanner";
import {useTypedDispatch, useTypedSelector} from "../../store";
import useScroll from "../../useScroll";
import {compact} from "../../utils/arrays";
import {useWindowSize} from "../_common/Carbon";

type Banner = {
  when: string;
  text: string | JSX.Element;
  subtext?: string;
  name: string;
  pathsInclude?: string[];
  pathsExclude?: string[];
  type?: NoticeType;
  icon?: IconName;
  wrapperClasses?: string;
  states?: string[];
};

/*
eg:
const banners: Banner[] = [
  {
    when: "11/11-11/25",
    text: "Test - Carbon Health clinics will be closed for Thanksgiving on Thursday, Nov 25.",
    subtext: <div>Please check specific clinic hours for other holiday <b>changes</b>.<div>,
    name: "Test Thanksgiving",
    pathsInclude: ["/locations/[slug]"],
    type: "info",
    icon: "info",
    "wrapperClasses": "!bg-yellow-200"
  },
];
*/

const format = "MM/DD";

// `bannerData` and `now` are added for Storybook. Don't pass them as a prop
export type Props = {bannerData?: Banner[]; now?: number};

const DynamicBanner: React.FC<Props> = ({bannerData, now}) => {
  const {y} = useScroll();
  const bannerWrapperRef = useRef<HTMLInputElement | null>(null);
  const windowSize = useWindowSize();
  const dispatch = useTypedDispatch();
  const {t} = useTranslation();

  const {pathname} = useRouter();

  const [banners, setBanners] = useState<Banner[]>([]);
  const [banner, setBanner] = useState<JSX.Element | null>(null);

  const {locations, locationsSorted} = useTypedSelector(({config}) => config);

  useEffect(() => {
    if (bannerData) return;
    fetchFeatureFlags({[FeatureFlag.GROWTH_WEBSITE_BANNER]: []}).then(res => {
      const bannersFetched: Banner[] = res[FeatureFlag.GROWTH_WEBSITE_BANNER];
      setBanners(bannersFetched);
    });
  }, [bannerData]);

  useEffect(() => {
    if (!locationsSorted) return;
    // Get the closest state (Didn't use the regions because this works with states)
    const closestState = locations?.[0]?.address.state;
    const bannersFound = (bannerData || banners).map((banner, i) => {
      const {
        when,
        pathsInclude,
        pathsExclude,
        type,
        text,
        subtext,
        icon = "info",
        wrapperClasses,
        states,
      } = banner;

      const [from, to] = when.split("-");
      const startTime = +moment(from, format);
      const endTime = +moment(to, format).clone().endOf("day");

      const dateMatch = moment(now).isBetween(startTime, endTime);
      const pathsMatch = pathsInclude ? pathsInclude.includes(pathname) : true; // if empty, show in all paths
      const pathsExcludeMatch = !pathsExclude?.includes(pathname);
      const statesMatch = states ? states.includes(closestState) : true; // if empty, show in all states
      if (!dateMatch || !pathsMatch || !pathsExcludeMatch || !statesMatch) return null;

      return (
        <Notice
          key={i}
          type={type}
          text={typeof text === "string" ? t(text) : text}
          subtext={t(subtext || "")}
          icon={icon}
          ignoreRange
          className={`rounded-none min-w-full justify-center ${wrapperClasses}`}
        />
      );
    });
    const bannerFound = compact(bannersFound)[0];
    setBanner(bannerFound);
  }, [bannerData, banners, dispatch, locations, locationsSorted, now, pathname, t]);

  useEffect(() => {
    if (!banner) return;
    // Get BannersWrapper height and update #banner-spacer's height
    const bannerSpacerEl = document.querySelector<HTMLElement>(`#${bannerSpacer}`);
    const bannersWrapperHeight = bannerWrapperRef.current?.offsetHeight;
    if (bannerSpacerEl && bannersWrapperHeight) {
      bannerSpacerEl.style.height = `${bannersWrapperHeight}px`;
    }
  }, [banner, bannerWrapperRef, windowSize]);

  return banner ? (
    <Collapse in={y > -32}>
      <div ref={bannerWrapperRef}>{banner}</div>
    </Collapse>
  ) : null;
};
export default React.memo(DynamicBanner);
