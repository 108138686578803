import {colors} from "@c10h/colors";
import {NextImageProps} from "@components/NextImage";
import React from "react";

import {isInTimeRange} from "../../../utils/timeUtils";
import {Btn, BtnProps} from "../V5Comp";

export type NoticeType = "info" | "warning" | "error" | "success";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: NoticeType;
  text?: string | JSX.Element;
  subtext?: string;
  btnText?: string;
  href?: BtnProps["href"];
  onCtaClick?: () => void;
  textClasses?: string;
  btnProps?: BtnProps;
  icon?: string;
  logo?: NextImageProps;
  startTime?: number;
  endTime?: number;
  ignoreRange?: boolean;
};

const Notice: React.FC<Props> = ({
  type = "warning",
  text,
  href,
  onCtaClick,
  btnText,
  subtext,
  className,
  textClasses,
  btnProps,
  icon,
  startTime = Date.now(),
  endTime,
  ignoreRange,
  ...props
}) => {
  const modes = {
    success: {
      backgroundColor: colors.lighterGreen,
      icon: "info",
    },
    info: {
      backgroundColor: colors.blue + 77, // HEX+
      icon: "info",
    },
    warning: {
      backgroundColor: colors.lightYellow,
      icon: "warning",
    },
    error: {
      backgroundColor: colors.red + 70, // HEX+
      icon: "warning",
    },
  };

  const buttonProps = {
    ...(btnProps || {}),
    ...(onCtaClick ? {onClick: onCtaClick} : {}),
    ...(href ? {href} : {}),
  };

  const showButton = Boolean(href || onCtaClick);

  return (!text && !subtext) || (!ignoreRange && !isInTimeRange(startTime, endTime)) ? null : (
    <div
      className={`p3 br5 font-ir fs14 df jcsb aic fdc-xs ${className || ""}`}
      {...props}
      style={{
        backgroundColor: modes[type].backgroundColor,
        width: showButton ? "100%" : "fit-content",
      }}
    >
      <div className="df aic pr2 mb2-xs">
        <span className={`mr4 fs30 cIcon-${icon || modes[type].icon}`} aria-hidden />
        <div className={textClasses}>
          <strong className="font-ib db lh16">{text}</strong>
          <span className="lh14 db">{subtext}</span>
        </div>
      </div>
      {showButton && (
        <Btn
          bg={colors.gray800 + 10} // HEX+
          hoverBg={colors.gray800 + 5} // HEX+
          color={colors.gray800}
          fontSize={0.75}
          {...buttonProps}
        >
          {btnText}
        </Btn>
      )}
    </div>
  );
};

export default Notice;
