export default [
  {
    slug: "illinois",
    name: "Illinois",
    latitude: 41.8336479,
    longitude: -87.8720463,
  },
  {
    slug: "connecticut",
    name: "Connecticut",
    latitude: 41.2982831,
    longitude: -72.9641152,
  },
  {
    slug: "georgia",
    name: "Georgia",
    latitude: 33.7676931,
    longitude: -84.4906437,
  },
  {
    slug: "michigan",
    name: "Michigan",
    latitude: 42.3526897,
    longitude: -83.1692445,
  },
  {
    slug: "north-carolina",
    name: "North Carolina",
    latitude: 35.2028147,
    longitude: -81.1269099,
  },
];
